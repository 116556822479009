<template>
  <va-card title="Request">
    <va-data-table
      :fields="fields"
      :data="items"
      :per-page="20"
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          :to="{ name: 'autostrada-request-view', params: { id: props.rowData.id } }"
          small
          color="info"
        >
          Переглянути
        </va-button>
        <va-button
          @click="remove(props.rowData.id)"
          small
          color="danger"
          class="ma-0"
        >
          Видалити
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [
        {
          name: 'created_at',
          title: 'Created at',
          width: '15%',
        },
        {
          name: 'type_name',
          title: 'Type',
          width: '10%',
        },
        {
          name: 'name',
          title: 'Name',
          width: '15%',
        },
        {
          name: 'email',
          title: 'email',
          width: '15%',
        },
        {
          name: 'phone',
          title: 'Phone',
          width: '15%',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    remove (id) {
      if (confirm('Дійсно видалити?')) {
        axios.delete(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-request/${id}`)
          .then(response => {
            const idx = this.items.findIndex(u => u.id === id)
            this.items.splice(idx, 1)
            this.showToast('Success')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-request`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.forEach((item) => {
            item.published = !!item.published
            const date = new Date(item.created_at * 1000)
            item.created_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
